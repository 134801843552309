import React, {useEffect, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import {DialogActions, DialogContent, DialogTitle, Fade} from "@material-ui/core";
import {Row} from "reactstrap";
import {downloadFile} from "../../../../core/services/utils";
import Dialog from "../../base/dialog";
import {useTranslation} from "react-i18next";
import {FilePreviewTypes} from "../../../../core/constants/enums";


const FilePreviewDialog = ({data, open, setOpen}) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const {t} = useTranslation();
    const translations = t("dialogs.filePreview", {returnObjects: true, first: pageNumber ?? 0, second: numPages ?? 0})

    /**
     * Sets the global worker for the pdfjs as soon as the component mounts.
     */
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])

    /**
     * Sets the page numbers and the selected page number as soon as the pdf document loads successfully.
     * @param numPages
     */
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
    }


    /**
     * Downloads the file of the technical file for the user by fetching the file then downloading it using an <a> tag.
     */
    const _downloadFile = async () => {
        setDownloadFailed(false);
        setDownloading(true)
        await fetch(data.url)
            .then(resp => {
                if (resp.status !== 200) return undefined;
                return resp.blob();
            })
            .then(blob => {
                if (!blob) {
                    setDownloadFailed(true);
                    return;
                }
                downloadFile(blob, data.fileName);
            })
            .catch((e) => {
                console.log(e?.response?.data);
                setDownloadFailed(true)
            });
        setDownloading(false)
    }


    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            maxWidth={'xl'}
            fullWidth
            arial-label={'Add Gift Card for Order modal'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className={'file-preview'}
        >
            <DialogTitle className={'title'} id="scroll-dialog-title">
                {translations?.title ?? ""}
            </DialogTitle>
            <DialogContent>
                <Row className={'px-3 w-100 d-flex justify-content-center'}>
                    <Fade in={downloadFailed} mountOnEnter unmountOnExit>
                        <div
                            className={'w-100 my-3 text-center align-items-center d-flex justify-content-center' +
                            ' text-sm'}>
                            <p>
                                {translations?.body?.pdf?.downloadFailed ?? ""}
                            </p>
                        </div>
                    </Fade>
                    {
                        data?.type === FilePreviewTypes.pdf
                            ? (
                                <>
                                    <Document
                                        file={data?.url}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        error={
                                            <div className={'mb-3 mt-4 py-5 d-flex justify-content-center' +
                                            ' align-items-center text-center'}>
                                                {translations?.body?.pdf?.loadFailed ?? ""}
                                            </div>
                                        }>
                                        <Page pageNumber={pageNumber}/>
                                        {
                                            numPages > 1 &&
                                            <div className={'page-controls'}>
                                                <button
                                                    className={'button secondary outlined'}
                                                    onClick={() => setPageNumber(prevState => Math.max(1, prevState - 1))}>
                                                    {translations?.body?.pdf?.actions?.previous ?? ""}
                                                </button>
                                                <span>
                                                    {translations?.body?.pdf?.actions?.number ?? ""}
                                                </span>
                                                <button
                                                    className={'button secondary outlined'}
                                                    onClick={() => setPageNumber(prevState => Math.min(numPages, prevState + 1))}>
                                                    {translations?.body?.pdf?.actions?.next ?? ""}
                                                </button>
                                            </div>}
                                    </Document>

                                </>
                            )
                            : data?.type === FilePreviewTypes.video
                                ? (
                                    <video width="100%"
                                           controls
                                           autoPlay
                                           className={'d-flex justify-content-center rounded-xxl'}
                                    >
                                        <source src={data?.url} type={`video/mp4`}/>
                                    </video>
                                )
                                : (
                                    <div className={'d-flex text-center justify-content-center my-3'}>
                                        <p>
                                            {translations?.unknown ?? ""}
                                        </p>
                                    </div>
                                )
                    }
                </Row>
            </DialogContent>
            <DialogActions className={'d-flex align-items-center justify-content-between'}>
                <button
                    disabled={!open || downloading}
                    className={'button secondary outlined px-7 py-2'}
                    type={'button'}
                    onClick={() => setOpen(false)}>
                    {translations?.actions?.close ?? ''}
                </button>
                <button
                    className={'button secondary outlined px-7 py-2'}
                    type={'button'}
                    disabled={!open || downloading}
                    onClick={_downloadFile}>
                    {translations?.actions?.download ?? ''}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default FilePreviewDialog;
